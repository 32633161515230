var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"uploadPictures"},_vm._l((_vm.templateList),function(item,index){return _c('div',{key:index,staticClass:"uploadClassBox"},[_c('div',{staticClass:"uploadTitle"},[_vm._v(_vm._s(item.templateName))]),_vm._l((item.itemList),function(childItem,childIndex){return _c('div',{key:childIndex,staticClass:"uploadGroup"},[_c('div',{staticClass:"uploadTip"},[(childItem.isRequired==2)?_c('span',[_vm._v("*")]):_vm._e(),_vm._v(_vm._s(childItem.typeName))]),_c('div',{staticClass:"uploadBox"},[_c('ul',[_c('ul',[_vm._l((childItem.imageList),function(imgs,imgIndex){return _c('li',{key:imgIndex,staticClass:"uploadImg"},[(_vm.type == 1)?_c('div',[_c('img',{staticClass:"deleteImg",attrs:{"src":require("../../assets/images/pending/delete.png"),"alt":""},on:{"click":function($event){return _vm.deleteImg(imgIndex,childItem.id)}}})]):_c('div',[_c('img',{directives:[{name:"show",rawName:"v-show",value:(childItem.status == 3),expression:"childItem.status == 3"}],staticClass:"deleteImg",attrs:{"src":require("../../assets/images/pending/delete.png"),"alt":""},on:{"click":function($event){return _vm.deleteImg(imgIndex,childItem.id)}}})]),_c('img',{staticClass:"showImg",attrs:{"src":imgs,"alt":""},on:{"click":function($event){return _vm.bigImg(childItem.imageList, imgIndex)}}})])}),(_vm.type == 1)?_c('div',[_c('li',{directives:[{name:"show",rawName:"v-show",value:(childItem.imageList.length < childItem.maximum),expression:"childItem.imageList.length < childItem.maximum"}],staticClass:"addButton",on:{"click":function($event){return _vm.isClickUpload(
                    childItem.imageList,
                    childItem.id,
                    childItem.maximum
                  )}}},[_c('img',{attrs:{"src":require("../../assets/images/pending/addImage.png"),"alt":""}})])]):_c('div',[_c('li',{directives:[{name:"show",rawName:"v-show",value:(
                  childItem.imageList.length < childItem.maximum &&
                  childItem.status == 3
                ),expression:"\n                  childItem.imageList.length < childItem.maximum &&\n                  childItem.status == 3\n                "}],staticClass:"addButton",on:{"click":function($event){return _vm.isClickUpload(
                    childItem.imageList,
                    childItem.id,
                    childItem.maximum
                  )}}},[_c('img',{attrs:{"src":require("../../assets/images/pending/addImage.png"),"alt":""}})])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(childItem.imgUrlList.length > 0 && !_vm.haikangShow),expression:"childItem.imgUrlList.length > 0 && !haikangShow"}],staticClass:"uploadImg templateImage"},[_c('img',{staticClass:"showImg",attrs:{"src":childItem.imgUrlList[0],"alt":""},on:{"click":function($event){return _vm.bigImg(childItem.imgUrlList, 0)}}}),_c('span',{staticClass:"showTip"},[_vm._v(_vm._s(childItem.imgUrlList.length)+"张示例图")])])],2)])])])})],2)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }