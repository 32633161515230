<template>
  <div class="productSerialNumber">
    <div class="contentTitle">
      海康产品序列号
      <div class="scanCode" v-if="AndroidOrIos" @click="haikangScanningCode">
        <i class="scanCodeIcon"></i>扫码
      </div>
    </div>
    <div class="contentTip"><span>*</span>可手动输入或直接扫二维码添加</div>
    <textarea
      v-model="serialNumberList[0].productContent" :key="iskey"
      class="serialNumber"
      placeholder="请填写实际设备序列号!多组序列号请用英文分号隔开! 例如：C87895885;C87895847"
    ></textarea>
  </div>
</template>

<script>
import { getScanCode } from "../../utils/upload";
import { dialog } from "../../utils/vant";
export default {
  props: {
    serialNumberList: {
      type: Object,
    },
    AndroidOrIos: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      iskey:1,
    };
  },
  mounted() {
    window.getScanCode_call = (res) => {
      this.getScanCode_call(res);
    };
  },
  methods: {
    getScanCode_call(res) {
      this.isKey++
      if (this.serialNumberList[0].productContent) {
        this.serialNumberList[0].productContent = `${this.serialNumberList[0].productContent};${res}`;
      } else {
        this.serialNumberList[0].productContent = res;
      }
    },
    // 海康扫码
    haikangScanningCode() {
      getScanCode();
    },
  },
};
</script>

<style></style>
