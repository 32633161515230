<template>
  <div class="productSerialNumber">
    <div v-for="(item, index) in productList" :key="index">
      <div class="contentTitle">
        {{ item.productName }}
        <div class="scanCode" v-if="AndroidOrIos" @click="scanningCode(item.id)">
          <i class="scanCodeIcon"></i>扫码
        </div>
      </div>
      <div class="contentTip">
        <span v-show="item.isRequired == 2 ? true : false">*</span
        >可手动输入或直接扫二维码添加
      </div>
      <textarea
        v-model="item.productContent"
        class="serialNumber"
        placeholder="请填写实际设备序列号!多组序列号请用英文分号隔开! 例如：C87895885;C87895847"
      ></textarea>
    </div>
  </div>
</template>

<script>
import { getScanCode } from "../../utils/upload";
export default {
  props: {
    productList: {
      type: Array,
      default: [],
    },
    AndroidOrIos: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      id: "",
    };
  },
  mounted() {
    window.getScanCode_call = (res) => {
      this.getScanCode_call(res);
    };
  },
  methods: {
    getScanCode_call(res) {
      this.productList.forEach((e) => {
        if (e.id == this.id) {
          if (e.productContent) {
            e.productContent = `${e.productContent};${res}`;
          } else {
            e.productContent = res;
          }
        }
      });
    },
    // 产品信息扫码
    scanningCode(id) {
      this.id = id;
      getScanCode();
    },
  },
};
</script>

<style>
</style>