<template>
  <div class="proofService">
    <div class="goBack">
      <i class="goBackClose" @click="jumpBack"></i>
    </div>
    <div class="proofTitle">上传服务凭证</div>
    <div class="proofTip">
      <span class="star">*</span
      ><span class="tip"
        >请上传维修信息，平台将会在第一时间审核，请您耐心等待（服务信息最多上传10次）</span
      >
    </div>
    <div class="proofContent">
      <div class="contentTitle">服务结果概述</div>
      <textarea
        v-model="inputProof[0].cnt"
        class="inputProof"
        maxlength="100"
        placeholder="请输入问题描述"
      ></textarea>
    </div>
    <!-- 海康产品 -->
    <haikangAPP
      v-if="serviceOrderNo"
      :AndroidOrIos="AndroidOrIos"
      :serialNumberList="serialNumberList"
    ></haikangAPP>
    <!-- 产品信息 -->
    <productListAPP
      v-if="productList == null ? false : true"
      :AndroidOrIos="AndroidOrIos"
      :productList="productList"
    ></productListAPP>
    <!-- 图片上传 -->
    <uploadAPPGroup
      :haikangShow="haikangShow"
      :type="type"
      :templateList="templateList"
    ></uploadAPPGroup>
    <div class="proofConfirm" @click="confirmUp">确认提交</div>
    <van-overlay :show="show">
      <van-loading size="24px" vertical>上传中...</van-loading>
    </van-overlay>
  </div>
</template>

<script>
import { serviceVoucherTempletInfoAPP, updateOrderTrackAPP } from "../api/home";
import { dialog } from "../utils/vant";
import { getLocation } from "../utils/upload";
import haikangAPP from "../components/uploadFile/haikangAPP";
import productListAPP from "../components/uploadFile/productListAPP";
import uploadAPPGroup from "../components/uploadFile/uploadAPPGroup";
export default {
  components: {
    haikangAPP,
    productListAPP,
    uploadAPPGroup,
  },
  data() {
    return {
      orderNo: this.$route.query.orderNo, //订单号
      userKey: this.$route.query.userKey, //唯一标识APP
      type: this.$route.query.type, //类型 1--获取 2--修改
      repairId: this.$route.query.repairId, //维修信息表Id
      trackId: this.$route.query.editTrackId, //修改轨迹的id
      trackCode: "10009", //轨迹编码
      requesstType: "5", //请求类型  1--微信 ,5--APP
      operatorType: "1", //操作类型
      trackType: "1", //轨迹类型
      longitude: "", //经度
      latitude: "", //纬度
      inputProof: [{ cnt: "", urls: [] }], //服务结果概述
      serviceOrderNo: false, //海康订单标识--序列号
      haikangShow: false, //海康订单示例图显示标识
      serialNumberList: [
        {
          id: 1111,
          isRequired: 1,
          productContent: "",
          productName: "海康产品序列号",
          templetCode: "1111",
        },
      ],
      //海康产品序列号
      productList: [], //产品数组
      templateList: [], //模板数据
      AndroidOrIos: true,
      show: false, //上传加载显示
    };
  },
  mounted() {
    this.getServiceVoucherTempletInfo();
    this.finishActivity();
    window.getLocation_call = (res) => {
      this.getLocation_call(res);
    };
  },
  methods: {
    // 区分安卓还是IOS
    finishActivity() {
      let ua = navigator.userAgent.toLowerCase();
      //android终端
      let isAndroid = ua.indexOf("Android") > -1 || ua.indexOf("Adr") > -1;
      //ios终端
      let isiOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        this.AndroidOrIos = true;
      } else if (/(Android)/i.test(navigator.userAgent)) {
        this.AndroidOrIos = false;
      }
    },
    // 获取原生APP经纬度回调函数
    getLocation_call(res) {
      var obj = JSON.parse(res);
      if (!(Number(obj.longitude) <= 0 || Number(obj.latitude) <= 0)) {
        this.longitude = obj.longitude;
        this.latitude = obj.latitude;
      }
    },
    // 获取服务凭证模板数据APP
    getServiceVoucherTempletInfo() {
      let obj = {
        orderNo: this.orderNo,
        userKey: this.userKey,
        type: this.type,
        repairId: this.repairId,
      };
      serviceVoucherTempletInfoAPP(obj).then((res) => {
        if (res.status) {
          //控制海康订单示例图不显示
          if (res.serviceOrderNo) {
            this.haikangShow = true;
          }
          // 控制海康产品序列号显示
          if (res.serviceOrderNo != null && res.needSerialNum == 1) {
            this.serviceOrderNo = true;
          }
          // 服务概述赋值
          if (res.serviceInfo) {
            this.inputProof = res.serviceInfo;
          }
          // 控制产品信息显示-数组赋值
          this.productList = res.productList;
          //模板数据
          this.templateList = res.templateList;
        }
      });
    },
    // 提交上传
    confirmUp() {
      // 描述信息校验
      if (this.inputProof[0].cnt == "" || this.inputProof[0].cnt == undefined) {
        this.$dialog.alert({
          message: "描述信息不能为空",
        });
        return false;
      }
      //部分凭证图片为必传，在此做必传校验
      for(var i = 0; i<this.templateList.length; i++){
        for(var j=0;j<this.templateList[i].itemList.length;j++){
          if(this.templateList[i].itemList[j].isRequired==2){
            console.log(this.templateList[i].itemList[j].imageList.length==0)
            if(this.templateList[i].itemList[j].imageList.length==0){
               this.$dialog.alert({
                  message: `${this.templateList[i].itemList[j].typeName}为必传凭证！`,
                });
                return false;
            }
          }
        }
      }
      let templateList = this.templateList;
      for (var i = 0; i < templateList.length; i++) {
        let itemList = templateList[i].itemList;
        for (var j = 0; j < itemList.length; j++) {
          // 审核不通过状态上传是变更为1
          if (itemList[j].status == 3) {
            itemList[j].status = 1;
          }
        }
      }
      //修改轨迹类型变更为4
      if (this.type == 2) {
        this.trackType = 4;
      }
      let productInfo = []; //海康序列号与产品信息选择其一
      if (this.serviceOrderNo) {
        productInfo = this.serialNumberList;
      } else {
        productInfo = this.productList;
      }
      for (var i = 0; i < productInfo.length; i++) {
        if (productInfo[i].isRequired == 2) {
          if (productInfo[i].productContent == "") {
            this.$dialog.alert({
              message: `${productInfo[i].productName}为必填项！`,
            });
            return false;
          }
        }
      }
      this.show = true;
      // 获取原生APP经纬度
      getLocation();
      // 原生接口回调函数参数赋值需要定时器
      setTimeout(() => {
        // 请求参数
        let obj = {
          userKey: this.userKey,
          orderNo: this.orderNo,
          trackCode: this.trackCode,
          requesstType: this.requesstType,
          operatorType: this.operatorType,
          trackType: this.trackType,
          trackId: this.trackId,
          repairId: this.repairId,
          longitude: this.longitude,
          latitude: this.latitude,
          serviceInfo: JSON.stringify(this.inputProof),
          productInfo: JSON.stringify(productInfo),
          templetInfo: JSON.stringify(this.templateList),
        };
        updateOrderTrackAPP(obj).then((res) => {
          if (res.status) {
            this.show = false;
            this.$dialog
              .alert({
                message: `${res.message}`,
              })
              .then(() => {
                window.history.go(-1);
              });
          } else {
            this.show = false;
            this.$dialog.alert({
              message: `${res.message}`,
            });
          }
        });
      }, 1000);
    },
    // 跳转返回
    jumpBack() {
      window.history.go(-1);
    },
  },
};
</script>

<style lang="less">
@import url("../styles/proofService.less");
</style>
