<template>
  <div class="uploadPictures">
    <div
      class="uploadClassBox"
      v-for="(item, index) in templateList"
      :key="index"
    >
      <div class="uploadTitle">{{ item.templateName }}</div>
      <div class="uploadGroup" v-for="(childItem, childIndex) in item.itemList" :key="childIndex">
        <div class="uploadTip"><span v-if="childItem.isRequired==2">*</span>{{ childItem.typeName }}</div>
        <div class="uploadBox">
          <ul>
            <ul>
              <!-- 上传图片数组模板 -->
              <li
                class="uploadImg"
                v-for="(imgs, imgIndex) in childItem.imageList"
                :key="imgIndex"
              >
                <div v-if="type == 1">
                  <img
                    class="deleteImg"
                    src="../../assets/images/pending/delete.png"
                    alt=""
                    @click="deleteImg(imgIndex,childItem.id)"
                  />
                </div>
                <div v-else>
                  <img
                    v-show="childItem.status == 3"
                    class="deleteImg"
                    src="../../assets/images/pending/delete.png"
                    alt=""
                    @click="deleteImg(imgIndex,childItem.id)"
                  />
                </div>
                <img
                  class="showImg"
                  :src="imgs"
                  alt=""
                  @click="bigImg(childItem.imageList, imgIndex)"
                />
              </li>
              <!-- 上传图片按钮 -->
              <div v-if="type == 1">
                <li
                  class="addButton"
                  @click="
                    isClickUpload(
                      childItem.imageList,
                      childItem.id,
                      childItem.maximum
                    )
                  "
                  v-show="childItem.imageList.length < childItem.maximum"
                >
                  <img src="../../assets/images/pending/addImage.png" alt="" />
                </li>
              </div>
              <div v-else>
                <li
                  class="addButton"
                  @click="
                    isClickUpload(
                      childItem.imageList,
                      childItem.id,
                      childItem.maximum
                    )
                  "
                  v-show="
                    childItem.imageList.length < childItem.maximum &&
                    childItem.status == 3
                  "
                >
                  <img src="../../assets/images/pending/addImage.png" alt="" />
                </li>
              </div>
              <!-- 示例图数组模板 -->
              <li
                class="uploadImg templateImage"
                v-show="childItem.imgUrlList.length > 0 && !haikangShow"
              >
                <img
                  class="showImg"
                  :src="childItem.imgUrlList[0]"
                  alt=""
                  @click="bigImg(childItem.imgUrlList, 0)"
                />
                <span class="showTip"
                  >{{ childItem.imgUrlList.length }}张示例图</span
                >
              </li>
            </ul>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ImagePreview } from "vant";
import { getNewCalls } from "../../utils/upload";
export default {
  props: {
    templateList: {
      type: Array,
      default: [],
    },
    type: {
      type: String,
      default: "",
    },
    haikangShow:{
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      imgId: "", //图片id
    };
  },
  mounted() {
    window.getUpdateImageUrl_call = (res) => {
      this.getUpdateImageUrl_call(res);
    };
  },
  methods: {
    isClickUpload(list, id, maximum) {
      this.imgId = id;
      getNewCalls(list, maximum);
    },
    //图片上传后的回调
    getUpdateImageUrl_call(res) {
      let imgs = JSON.parse(res);
      this.templateList.forEach((e) => {
        e.itemList.forEach((i) => {
          if (i.id == this.imgId) {
            imgs.map((o) => {
              i.imageList.push(o.filePath);
            });
          }
        });
      });
    },
    //图片预览
    bigImg(imgs, index) {
      ImagePreview({
        images: imgs, // 预览图片的那个数组
        showIndex: true,
        startPosition: index, // 指明预览第几张图
      });
    },
    //删除图片
    deleteImg(index,id) {
      this.templateList.forEach((e) => {
        e.itemList.forEach((i) => {
          if (i.id == id) {
            i.imageList.splice(index, 1);
          }
        });
      });
    },
  },
};
</script>

<style>
</style>